import React from "react";
import { useRef } from "react";
import "./Join.css";
import emailjs from "@emailjs/browser";

const Join = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_a4hatgh",
        "template_ilmb1k6",
        form.current,
        "LMvFlNAHgDontk_AK"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div className="Join" id="join-us">
      <div className="left-j">
        <hr />
        <div>
          <span className="stroke-text">PRÊT À </span>
          <span>Augmenter</span>
        </div>

        <div>
          <span>vos Talents </span>
          <span className="stroke-text">AVEC NOUS</span>
        </div>
        <div className="right-j">
          <form
            ref={form}
            action=""
            className="email-container"
            onSubmit={sendEmail}
          >
            <input
              type="email"
              name="user_mail"
              placeholder="Saisir votre E-mail"
            />
            <button className="btn btn-j">Rejoignez-nous</button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Join;
