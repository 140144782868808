import React from "react";
import "./Footer.css";
import Logo from "../../assets/logo2.png";

const Footer = () => {
  return (
    <div className="Footer-container">
      <hr />
      <div className="Footer">
        <img src={Logo} alt="" />
      </div>
      <div></div>
    </div>
  );
};
export default Footer;
