import React from "react";
import "./Reasons.css";
import image1 from "../../assets/image11.png";
import image2 from "../../assets/image22.png";
import image3 from "../../assets/image33.png";
import image4 from "../../assets/image44.png";
import nb from "../../assets/nb.png";
/*import adidas from "../../assets/adidas.png";
import nike from "../../assets/nike.png";*/
import tick from "../../assets/tick.png";
const Reasons = () => {
  return (
    <div className="Reasons" id="reasons">
      <div className="left-r">
        <img src={image1} alt="" />
        <img src={image2} alt="" />
        <img src={image3} alt="" />
        <img src={image4} alt="" />
      </div>
      <div className="right-r">
        <span>CERTAINES RAISONS</span>
        <div>
          <span className="stroke-text">POURQUOI </span>
          <span> NOUS </span>
          <span className="stroke-text">CHOISIR</span>
        </div>
        <div className="details-r">
          <div>
            <img src={tick} alt=""></img>
            <span>3 COACH EXPERTS</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>ENTRAÎNEZ-VOUS PLUS INTELLIGEMMENT ET PLUS RAPIDEMENT</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>PROGRAMME SPÉCIAL POUR LES NOUVEAUX JOUEURS</span>
          </div>
          <div>
            <img src={tick} alt="" />
            <span>MATCHS COMPÉTITIFS CONTRE D'AUTRES ACADÉMIES</span>
          </div>
        </div>
        <span
          style={{
            color: "var(--orange)",
            fontWeight: "normal",
          }}
        >
          NOTRE PARTENAIRE
        </span>
        <div className="partners">
          <img src={nb} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Reasons;
