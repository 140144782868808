import image1 from "../assets/zaydoun.jpg";
import image2 from "../assets/younes.jpg";
import image3 from "../assets/miled.jpg";

export const testimonialsData = [
  {
    image: image1,
    review: "Zied Ben Mbarek entreneur professionel avec +10 ans d'experiences",
    name: "ZIED BEN MBAREK",
    status: "COACH",
  },
  {
    image: image2,
    review: "Youness Azzouz ",
    name: "YOUNESS AZZOUZ",
    status: "COACH",
  },
  {
    image: image3,
    review: " amiral miled",
    name: "MILED AMIRAL",
    status: "CUSTOMER",
  },
];
